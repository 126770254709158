import React, { useState } from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import ShewronLeft from 'assets/icons/chevronleft.svg'
import ShewronRight from 'assets/icons/chevronright.svg'

const Container = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
`
const Arrow = styled.div`
    text-align: center;
    position: absolute;
    top: 45%;
    width: 5%;
    font-size: 3em;
    cursor: pointer;
    user-select: none;
    ${(props) =>
        props.right
            ? css`
                  left: 93%;
              `
            : css`
                  left: 2%;
              `}
    & :hover {
        fill: white;
        transition-duration: 1s;
    }
`
const SlideShow = () => {
    const [index, setIndex] = useState(0)
    const { allFile } = useStaticQuery(
        graphql`
            query {
                allFile(filter: { relativeDirectory: { eq: "poroshok" } }) {
                    edges {
                        node {
                            id
                            name
                            childImageSharp {
                                fluid(
                                    maxWidth: 1200
                                    maxHeight: 900
                                    cropFocus: CENTER
                                ) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        `,
    )
    const length = allFile.edges.length - 1
    const handleNext = () =>
        index === length ? setIndex(0) : setIndex(index + 1)
    const handlePrevious = () =>
        index === 0 ? setIndex(length) : setIndex(index - 1)
    const { node } = allFile.edges[index]
    return (
        <Container>
            <Img alt="Фото" fluid={node.childImageSharp.fluid} key={node.id} />
            <Arrow aria-label="Попереднє фото" onClick={() => handlePrevious()}>
                <ShewronLeft />
            </Arrow>
            <Arrow
                aria-label="Наступне фото"
                right
                onClick={() => handleNext()}
            >
                <ShewronRight />
            </Arrow>
        </Container>
    )
}
export default SlideShow
