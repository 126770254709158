import styled from 'styled-components'
import React from 'react'

import { breakpoints } from '../ui-kit/globalValue'

const Text = styled.li`
    padding: 10px 0;
    font-size: 20px;
    line-height: 1.2;

    @media (min-width: ${breakpoints.tablet}) {
        width: 75%;
        margin: 0 auto;
    }
`
export const AboutHistoryElementText = (props) => {
    return <Text>{props.children}</Text>
}
