import styled from 'styled-components'

import { breakpoints } from 'components/ui-kit/globalValue'

export const Blockquote = styled.blockquote`
    border-left: 2px solid #222;
    padding-left: 10px;
    margin: 10px 0;
    line-height: 1.2;
    & > :last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
    & > :first-child {
        margin-top: 0;
    }
    & p {
        margin-bottom: 1em;
    }
    @media (min-width: ${breakpoints.tablet}) {
        font-size: 24px;
        border-left: 3px solid #222;
    }
    @media (min-width: ${breakpoints.desktop}) {
        line-height: 32px;
    }
`
