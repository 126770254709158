import React from 'react'
import styled from 'styled-components'
import { Link } from 'i18n/Link'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Page } from '../components/layout/Page'
import { Container } from '../components/ui-kit/Container'
import { Title } from '../components/ui-kit/title'
import { breakpoints } from '../components/ui-kit/globalValue'
import { P } from 'components/ui-kit/P'
import { Blockquote } from 'components/ui-kit/Blockquote'
import { ImageBox } from 'components/ui-kit/ImageBox'
import SlideShow from 'components/ui-kit/sliderMini'
import { useTranslations } from 'i18n/useTranslations'
import { MDXRenderer } from 'components/ui-kit/MDXRenderer'
import { AboutHistoryElementDate } from 'components/ui-kit/AboutHistoryElementDate'
import { AboutHistoryElementText } from 'components/ui-kit/AboutHistoryElementText'

const DivT = styled.div`
    @media (min-width: ${breakpoints.tablet}) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 60%;
    }
`

const DescriptionBlock = styled.div`
    @media (min-width: ${breakpoints.tablet}) {
        display: flex;
        padding: 15px 0;
    }
`

const Description = styled(P)`
    font-size: 20px;
    @media (min-width: ${breakpoints.desktop}) {
        font-size: 24px;
        line-height: 40px;
    }
`
const DescriptionBold = styled(Blockquote)`
    font-size: 20px;
    @media (min-width: ${breakpoints.desktop}) {
        font-size: 24px;
        line-height: 40px;
    }
`

const DivS = styled.div`
    width: 100%;
    @media (min-width: ${breakpoints.tablet}) {
        width: 130%;
        padding-left: 20px;
    }
    @media (min-width: ${breakpoints.desktop}) {
        width: 80%;
        padding-left: 20px;
    }
`

const AboutPage = (props) => {
    const translations = useTranslations()
    const { pageData, aboutHistory } = props.data
    return (
        <Page>
            <Helmet>
                <title>
                    {pageData.frontmatter.title} - {translations.logo} -
                    {translations.siteTitle}
                </title>
                <meta
                    name="description"
                    content={pageData.frontmatter.description}
                />
            </Helmet>
            <Container>
                <div>
                    <Title as="h1">{translations.siteTitle}</Title>
                    <Title as="h3">{translations.aboutTopTitle}</Title>
                </div>
                <DescriptionBlock>
                    <DivT>
                        <MDXRenderer
                            components={{
                                blockquote: DescriptionBold,
                                p: Description,
                            }}
                        >
                            {pageData.code.body}
                        </MDXRenderer>
                    </DivT>
                    <DivS>
                        <SlideShow />
                    </DivS>
                </DescriptionBlock>
            </Container>
            <Container>
                <Title as="h3" view="underlined">
                    {translations.ourHistory}
                </Title>
                <MDXRenderer
                    components={{
                        p: AboutHistoryElementDate,
                        li: AboutHistoryElementText,
                    }}
                >
                    {aboutHistory.code.body}
                </MDXRenderer>
            </Container>
            <Link
                to="/product-images/"
                state={{
                    modal: true,
                }}
            >
                <ImageBox images={props.data.images.edges} />
            </Link>
            <Container>
                <Title as="h4">{translations.bottomTitle}</Title>
            </Container>
        </Page>
    )
}
export default AboutPage

export const query = graphql`
    query($locale: String! = "uk") {
        images: allFile(filter: { relativeDirectory: { eq: "products" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        fluid(
                            maxWidth: 1200
                            maxHeight: 900
                            cropFocus: CENTER
                        ) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        pageData: mdx(
            fields: { locale: { eq: $locale }, pageName: { eq: "about" } }
        ) {
            frontmatter {
                title
                description
            }
            code {
                body
            }
        }
        aboutHistory: mdx(
            fields: {
                locale: { eq: $locale }
                pageName: { eq: "about-history" }
            }
        ) {
            code {
                body
            }
        }
    }
`
