import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { breakpoints } from 'components/ui-kit/globalValue'

const ImageBoxS = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0.2rem;
    max-height: 224px;
    justify-content: space-between;
    overflow: hidden;
    @media (min-width: ${breakpoints.tablet}) {
        margin: 2rem 0.2rem;
    }
`
const StyledImg = styled(Img)`
    max-height: 230px;
    min-width: 300px;
    flex-grow: 1;
    object-fit: cover;
    margin: 0 0.3rem;
`
const Slide = ({ images }) => {
    return images.map(({ node }) => (
        <StyledImg key={node.id} fluid={node.childImageSharp.fluid} />
    ))
}

export const ImageBox = (props) => {
    return (
        <ImageBoxS>
            <Slide images={props.images} />
        </ImageBoxS>
    )
}
